import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { OrderCardPopup } from "@/pages/CardPage/components/OrderCardPopup";
import { useToggler } from "@/shared/hooks/useToggler";
export var NewCardButton = function () {
    var newCardPopup = useToggler();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { padding: "22px 20px", bgColor: "general-white", borderRadius: "22px", display: "flex", alignItems: "center", gap: "8px", sx: {
                cursor: "pointer",
            }, onClick: newCardPopup.on },
            React.createElement(Box, { width: "68px", height: "48px", bgColor: "black-100", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "6px" },
                React.createElement(BaseIcon, { icon: "Card", color: "deep-blue-500", size: 32 })),
            React.createElement(Typography, { type: "text16Bold", color: "deep-blue-500" }, "New card")),
        newCardPopup.enabled && (React.createElement(OrderCardPopup, { onClose: newCardPopup.off }))));
};
