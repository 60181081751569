import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { VerificationStatus } from "@/api/registration/types";
import { VerificationBanner } from "@/entities/Verification";
import { useGetVerificationInfo } from "@/api/registration";
import { Balances } from "@/pages/HomePage/components/Balances";
import { TotalBalance } from "@/pages/HomePage/components/TotalBalance";
import { Transactions } from "@/widgets/Transactions";
export var FiatsPage = observer(function () {
    var media = useMedia();
    var data = useGetVerificationInfo().data;
    var status = data === null || data === void 0 ? void 0 : data.status;
    var accounts = useStoreContext(AccountListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var balanceList = useStore(BalanceListStore, currencyList);
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.list.map(function (acc) { return acc.account; }));
    }, [balanceList, accounts.list]);
    var account = accounts.checking;
    var findCurrencyBalance = function (currency) {
        var _a, _b;
        return ((_b = (currency.slug && ((_a = balanceList.data[currency.slug]) === null || _a === void 0 ? void 0 : _a.balance))) !== null && _b !== void 0 ? _b : "0");
    };
    var loaded = accounts.crypto.every(function (item) { var _a; return (_a = balanceList.data[item.account]) === null || _a === void 0 ? void 0 : _a.balance; });
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Typography, { type: media.desktop ? "text32Bold" : "text24Bold" }, "Fiat assets")),
        status !== VerificationStatus.GREEN && (React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr", gap: "20px" },
            React.createElement(VerificationBanner, null))),
        React.createElement(TotalBalance, { type: "FIAT" }),
        React.createElement(Balances, { type: "FIAT" }),
        React.createElement(Typography, { type: "text24", fontWeight: 500 }, "Transactions"),
        React.createElement(Transactions, { accountIds: (account === null || account === void 0 ? void 0 : account.account) ? [account === null || account === void 0 ? void 0 : account.account] : [] })));
});
