var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { PromoCodeInput } from "@/shared/components/PromoCodeInput";
import { CardOffersStore } from "@/shared/stores/CarfOffersStore";
import { PaymentCurrencyDropdown } from "@/shared/components/PaymentCurrencyDropdown";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { action, runInAction } from "mobx";
import { Input } from "@/shared/components/Input";
import { onChange } from "@/shared/utils/input";
import { OrderCardStore } from "@/pages/CardPage/store/OrderCardStore";
import { observer } from "mobx-react-lite";
export var PaymentSummary = observer(function () {
    var balanceList = useStoreContext(BalanceListStore);
    var accountList = useStoreContext(AccountListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var orderCard = useStoreContext(OrderCardStore);
    var offerList = useStore(CardOffersStore);
    var account = accountList.checking;
    React.useEffect(function () {
        offerList.sync();
        var syncData = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!account) return [3 /*break*/, 2];
                        return [4 /*yield*/, accountList.sync()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(account === null || account === void 0 ? void 0 : account.account)) return [3 /*break*/, 4];
                        return [4 /*yield*/, balanceList.sync(account.account)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        syncData();
    }, [balanceList]);
    var onCurrencyChange = function (c, enoughFunds, convertedAmount) {
        runInAction(function () {
            orderCard.selectedCurrency = c;
            orderCard.enoughFunds = enoughFunds;
            orderCard.convertedAmount = convertedAmount;
        });
    };
    var applyPromoCode = action(function () {
        orderCard.promoCodeApplied = true;
    });
    var clearPromoCode = action(function () {
        orderCard.promoCodeApplied = false;
        orderCard.promoCode = "";
    });
    return (React.createElement(Box, { flexGrow: 1 },
        React.createElement(PaymentCurrencyDropdown, { totalAmount: orderCard.promoCodeApplied ? "5" : "10", onCurrencyChange: onCurrencyChange, currencies: currencyList.list, selectedCurrency: orderCard.selectedCurrency || currencyList.list[0], balances: balanceList.data }),
        React.createElement(Box, { marginTop: "32px" },
            React.createElement(Input, { value: "", disabled: true, label: "Referral code", InputProps: {
                    endAdornment: React.createElement(BaseIcon, { icon: "Success" }),
                } })),
        React.createElement(Box, { marginTop: "32px" },
            React.createElement(PromoCodeInput, { value: orderCard.promoCode, onChange: onChange(action(function (val) {
                    if (!orderCard.promoCodeApplied) {
                        orderCard.promoCode = val;
                    }
                })), applied: orderCard.promoCodeApplied, onApply: function () {
                    applyPromoCode();
                }, onClear: function () {
                    clearPromoCode();
                }, isValid: orderCard.isPromoCodeValid })),
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "16px" },
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text14", color: "black-500" }, "Total")),
            React.createElement(Box, null,
                React.createElement(Typography, null,
                    "USD ",
                    orderCard.promoCodeApplied ? 5 : 10)))));
});
