import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Header } from "@/pages/DepositPage/components/Header";
import { DepositType, ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { DropdownInput } from "@/shared/components/DropdownInput";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { QRCode } from "@/shared/components/QRCode";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { TokenPopup } from "@/shared/components/TokenPopup";
import { useCopy } from "@/shared/hooks/useCopy";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { BlockchainPopup } from "@/shared/components/BlockchainPopup";
import { useGetWalletAddress } from "@/api/wallet/useGetAddress";
import { generateQueryPath } from "@/shared/utils/path";
export var DepositFiat = observer(function () {
    var _a, _b, _c, _d;
    var media = useMedia();
    var params = useParams();
    var searchParams = useSearchParams()[0];
    var tokenPopup = useToggler();
    var blockchainPopup = useToggler();
    var navigate = useNavigate();
    var currencyList = useStoreContext(CurrencyListStore);
    var account = params.id ? params.id : "";
    var blockchain = (_a = searchParams.get("blockchain")) !== null && _a !== void 0 ? _a : "";
    var currencySlug = (_b = searchParams.get("currency")) !== null && _b !== void 0 ? _b : "";
    var currency = currencySlug
        ? currencyList.getBySlug(currencySlug)
        : undefined;
    var loading = params.id ? !currency : false;
    var addressData = useGetWalletAddress(account, currencySlug, blockchain).data;
    var copy = useCopy(addressData ? addressData.address : "");
    return (React.createElement(React.Fragment, null,
        tokenPopup.enabled && (React.createElement(TokenPopup, { onClose: tokenPopup.off, type: "FIAT", onSelect: function (slug) {
                tokenPopup.off();
                navigate(generateQueryPath(ROUTES.deposit, {
                    id: account,
                    type: DepositType.Crypto,
                }, {
                    currency: slug,
                }), {
                    replace: true,
                });
            } })),
        blockchainPopup.enabled && (React.createElement(BlockchainPopup, { currencySlug: currencySlug, onClose: blockchainPopup.off, onSelect: function (blockchain) {
                blockchainPopup.off();
                navigate(generateQueryPath(ROUTES.deposit, {
                    id: account,
                    type: DepositType.Crypto,
                }, {
                    currency: currencySlug,
                    blockchain: blockchain,
                }), {
                    replace: false,
                });
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Header, { active: DepositType.Crypto }),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
                    React.createElement(DropdownInput, { loading: loading, onClick: tokenPopup.on }, currency ? (React.createElement(React.Fragment, null,
                        React.createElement(TokenIcon, { symbol: currency.slug, size: 36 }),
                        React.createElement(Typography, { type: "text16" }, currency.shortName))) : (React.createElement(Typography, { type: "text16" }, "Select Currency"))),
                    React.createElement(DropdownInput, { loading: loading, onClick: blockchainPopup.on }, currency && currency.tokens && blockchain ? (React.createElement(Typography, { type: "text16" }, (_c = currencyList.blockchainList.find(function (item) {
                        return item.slug === blockchain;
                    })) === null || _c === void 0 ? void 0 : _c.name)) : (React.createElement(Typography, { type: "text16" }, "Select Network"))))), loading: loading, card: addressData && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" },
                React.createElement(QRCode, { dark: !media.desktop, value: addressData.address }),
                React.createElement(Box, { display: "flex", gap: "30px", width: "100%", justifyContent: "space-between" },
                    React.createElement(Box, { display: "flex", flexDirection: "column" },
                        React.createElement(Typography, { type: "text14", color: "black-400" }, "Wallet address"),
                        React.createElement(Typography, { type: "text16", color: media.desktop
                                ? "general-white"
                                : undefined, wordBreak: "break-word" }, addressData.address)),
                    React.createElement(Button, { variant: "LightBlue", size: "s", onClick: copy }, "Copy")),
                account && (React.createElement(Typography, { type: "text14", color: media.desktop
                        ? "general-white"
                        : "black-1200", bgColor: media.desktop ? "black-1000" : undefined, borderRadius: "12px", padding: media.desktop ? "20px" : undefined, boxSizing: "border-box", width: "100%", display: "flex", alignItems: "center", gap: "10px" },
                    React.createElement(BaseIcon, { icon: "Info", color: "general-orange", size: 14 }),
                    "Only send",
                    " ", (_d = currency === null || currency === void 0 ? void 0 : currency.shortName) === null || _d === void 0 ? void 0 :
                    _d.toUpperCase(),
                    " ", "(".concat(blockchain === null || blockchain === void 0 ? void 0 : blockchain.toUpperCase(), ")"),
                    " to this address")))) })));
});
