import React from "react";
import { useStoreContext } from "@/shared/hooks/useStore";
import { OrderCardStore } from "@/pages/CardPage/store/OrderCardStore";
import { OrderCardStepHeader } from "@/pages/CardPage/components/OrderCardStepHeader";
import { Box, Button } from "@/shared/components";
import { PaymentCard } from "@/pages/CardPage/components/OrderConfirmation/PaymentCard";
import { PaymentSummary } from "@/pages/CardPage/components/OrderConfirmation/PaymentSummary";
export var OrderConfirmation = function (_a) {
    var onPopupClose = _a.onPopupClose;
    var orderCard = useStoreContext(OrderCardStore);
    var onSubmit = function () {
        orderCard.createCardRequest();
        onPopupClose();
        orderCard.success = true;
    };
    return (React.createElement(Box, { width: "100%", maxWidth: "800px", marginTop: "40px" },
        React.createElement(OrderCardStepHeader, { title: "Order confirmation", action: orderCard.previousStep }),
        React.createElement(Box, { marginTop: "50px", display: "flex", gap: "24px" },
            React.createElement(Box, { marginX: "24px" },
                React.createElement(PaymentCard, null)),
            React.createElement(PaymentSummary, null)),
        React.createElement(Box, { width: "100%", display: "flex", justifyContent: "center", marginTop: "40px" },
            React.createElement(Button, { width: "400px", style: { borderRadius: "16px" }, onClick: onSubmit, size: "l" },
                "Pay ",
                orderCard.promoCodeApplied ? 5 : 10,
                " USD"))));
};
