import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { SelectAssetPopup } from "@/pages/HomePage/components/TotalBalance/SelectAssetPopup";
import { DepositType, ExchangeType, ROUTES, TransferType } from "@/routes";
import { Box, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { Loader } from "@/shared/components/Loader";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { BalanceTypeStore, } from "@/shared/stores/BalanceTypeStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { UserCurrency, UserCurrencyStore, } from "@/shared/stores/UserCurrencyStore";
import { generateQueryPath } from "@/shared/utils/path";
export var TotalBalance = observer(function (_a) {
    var type = _a.type;
    var popup = useToggler();
    var data = useGetVerificationInfo().data;
    var phone = useMedia().phone;
    var balanceType = useStoreContext(BalanceTypeStore);
    var accountList = useStoreContext(AccountListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var balanceList = useStore(BalanceListStore, currencyList);
    var userCurrency = useStoreContext(UserCurrencyStore);
    var isDisabled = (data === null || data === void 0 ? void 0 : data.status) !== VerificationStatus.GREEN;
    var account = accountList.checking;
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accountList.list.map(function (item) { return item.account; }));
    }, [balanceList, accountList]);
    var getBalance = function (currencies) {
        // TODO: if disabled
        if (!userCurrency.selected) {
            return undefined;
        }
        var currencyNamesFiltered = currencies
            .filter(function (currency) { return currency.eurPrice && currency.slug; })
            .map(function (currency) { return currency.slug; });
        var balances = currencyNamesFiltered.map(function (currency) { return balanceList.data[currency]; });
        var total = "0";
        // eslint-disable-next-line no-plusplus
        for (var i = 0; i < balances.length; i++) {
            var item = balances[i];
            if (!item) {
                return undefined;
            }
            var fromRate = currencyList.rates[item.currency.toLowerCase()];
            var toRate = currencyList.rates[userCurrency.selected];
            if (!(fromRate === null || fromRate === void 0 ? void 0 : fromRate.eurPrice) || !(toRate === null || toRate === void 0 ? void 0 : toRate.eurPrice)) {
                return undefined;
            }
            total = new BigNumber(total)
                .plus(new BigNumber(fromRate.eurPrice)
                .multipliedBy(item.balance)
                .dividedBy(toRate.eurPrice)
                .toFixed())
                .toFixed();
        }
        return total;
    };
    var balance = type === "ALL"
        ? getBalance(currencyList.list)
        : type === "CRYPTO"
            ? getBalance(currencyList.crypto)
            : type === "FIAT"
                ? getBalance(currencyList.fiat)
                : undefined;
    return (React.createElement(React.Fragment, null,
        popup.enabled && React.createElement(SelectAssetPopup, { onClose: popup.off }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" },
                React.createElement(Typography, { type: "text16", color: "black-500" }, "Total balance"),
                React.createElement(Box, { display: "flex", alignItems: "center", gap: "15px" }, balance === undefined ? (React.createElement(Loader, { size: 24, containerSize: 47 })) : (React.createElement(Typography, { type: "text28" },
                    userCurrency.selected === UserCurrency.Eur
                        ? "€"
                        : userCurrency.selected === UserCurrency.Usd
                            ? "$"
                            : null,
                    formattedTokenAmount(balance))))),
            React.createElement(Box, { display: "flex", justifyContent: "space-between" }, phone ? (React.createElement(Box, { display: "grid", alignItems: "center", gap: "10px", gridTemplateColumns: "1fr 1fr 1fr" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" },
                    React.createElement(Button, { padding: "10px", icon: "Plus", size: "icon", iconSize: 12, width: 46 }),
                    React.createElement(Typography, { type: "text14", color: "deep-blue-500" }, "Add funds")),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" },
                    React.createElement(Button, { variant: "Gray", padding: "10px", icon: "Transfer", size: "icon", iconSize: 24, width: 46 }),
                    React.createElement(Typography, { type: "text14" }, "Transfer")),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" },
                    React.createElement(Button, { variant: "Gray", padding: "10px", icon: "Exchange", size: "icon", iconSize: 24, width: 46 }),
                    React.createElement(Typography, { type: "text14" }, "Exchange")))) : (React.createElement(Box, { display: "flex", gap: "8px" },
                React.createElement(Button, { icon: "Deposit", iconSize: 24, disabled: isDisabled, LinkComponent: !isDisabled ? Link : undefined, to: generatePath(ROUTES.deposit, {
                        type: DepositType.Crypto,
                        id: account === null || account === void 0 ? void 0 : account.account,
                    }) }, "Add money"),
                React.createElement(Button, { icon: "Transfer", iconSize: 24, variant: "LightBlue", disabled: isDisabled, LinkComponent: !isDisabled ? Link : undefined, to: generatePath(ROUTES.transfer, {
                        type: TransferType.Crypto,
                    }) }, "Transfer"),
                React.createElement(Button, { icon: "Exchange", iconSize: 24, variant: "LightBlue", disabled: isDisabled, LinkComponent: !isDisabled ? Link : undefined, to: generateQueryPath(ROUTES.exchange, {
                        type: ExchangeType.Crypto,
                    }) }, "Exchange")))))));
});
